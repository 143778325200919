body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  height: 100vh;
}



#root{
  margin: 0 auto;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.container {
  width: 100%;
  max-height: 97vh;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
  
}

.form-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.list-container {

  padding: 20px;
  min-height: 0px;
  overflow-y: auto;
  max-height: calc(100vh - 160px); /* Adjust height to leave space for the form and some margin */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.list-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

h1 {
  text-align: center;
  color: #333;
}

button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

input[type="text"], textarea {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
  resize: vertical;
}

button[type="submit"] {
  background-color: #28a745;
}

button[type="submit"]:hover {
  background-color: #218838;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

p {
  margin: 5px 0;
  color: #666;
}

.habit-item {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 5px;
}

.habit-details {
  margin-bottom: 10px;
}

.habit-details h2 {
  margin: 0;
  font-size: 18px;
}

.habit-details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.habit-buttons {
  display: flex;
  justify-content: space-between;
}

.habit-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  text-align: center;
  flex: 1;
  margin: 0 5px;
}

.checkin-button {
  background-color: #28a745;
}

.checkin-button:hover {
  background-color: #218838;
}

.delete-habit {
  background-color: #dc3545;
}

.delete-habit:hover {
  background-color: #c82333;
}

.progress-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.delete-checkin {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-checkin:hover {
  background-color: #c82333;
}
